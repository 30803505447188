import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px auto;
  width: 80%;
  max-width: 1200px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const Results = styled.div`
  margin-top: 20px;
`;

const App = () => {
  const [humanText, setHumanText] = useState("");
  const [aiText, setAIText] = useState("");
  const [comparisonResult, setComparisonResult] = useState([]);

  const handleCompare = async () => {
    const formData = new FormData();
    formData.append("human", new Blob([humanText], { type: "text/plain" }));
    formData.append("ai", new Blob([aiText], { type: "text/plain" }));

    try {
      const response = await axios.post("http://localhost:8000/compare", formData);
      setComparisonResult(response.data.differences);
    } catch (error) {
      console.error("Error during comparison:", error);
    }
  };

  return (
    <Container>
      <h1>Text Comparison Tool</h1>
      <TextArea
        placeholder="Paste Human-generated text here"
        value={humanText}
        onChange={(e) => setHumanText(e.target.value)}
      />
      <TextArea
        placeholder="Paste AI-generated text here"
        value={aiText}
        onChange={(e) => setAIText(e.target.value)}
      />
      <Button onClick={handleCompare}>Compare Texts</Button>
      <Results>
        <h2>Comparison Results</h2>
        <ul>
          {comparisonResult.map((diff, index) => (
            <li key={index}>
              <b>{diff.action}:</b> "{diff.text1_segment}" vs "{diff.text2_segment}"
            </li>
          ))}
        </ul>
      </Results>
    </Container>
  );
};

export default App;
